import React from "react";
import Seo from "../../components/seo";
import Layout from "../../Layout";
import Breadcrumbs from "../../components/Breadcrumbs";
import Image1 from "./images/potato1.jpg";
import Image2 from "./images/potato2.jpg";
import Image3 from "./images/potato3.webp";
import Image4 from "./images/potato4.jpg";
import Image5 from "./images/potato5.jpg";
import Image6 from "./images/potato6.jpg";
import FarmImage1 from "./images/farm1.jpg";
import FarmImage2 from "./images/farm2.jpg";

const images = [Image1, Image2, Image3, Image4, Image5, Image6];

const potatoBenefits = [
  {
    text: "Nutrient-Rich",
    details: "Packed with vitamin C, potassium, vitamin B6, and fiber.",
  },
  {
    text: "Heart Health",
    details:
      "High in potassium, helps regulate blood pressure and lowers cholesterol.",
  },
  {
    text: "Digestive Health",
    details: "Fiber promotes regular digestion and prevents constipation.",
  },
  { text: "Immune Boost", details: "Vitamin C strengthens the immune system." },
  {
    text: "Skin Health",
    details: "Vitamin C supports collagen production for healthy skin.",
  },
  {
    text: "Weight Management",
    details: "Fiber keeps you full longer, aiding in weight control.",
  },
  {
    text: "Energy",
    details: "Complex carbohydrates provide a steady source of energy.",
  },
  {
    text: "Bone Health",
    details: "Contains magnesium and phosphorus for strong bones.",
  },
];

const PotatoPage = () => (
  <Layout>
    <Seo title="Potato" />
    <Breadcrumbs title="Potato" />
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8">
          <style>
            {`
              .fixed-size { width: 200px; height: 200px; object-fit: cover; margin: 3px; }
              .narrow-paragraph { max-width: 700px; margin: 0 auto; }
              .custom-table { width: 100%; margin-top: 20px; border-collapse: collapse; }
              .custom-table th, .custom-table td { border: 1px solid #ddd; padding: 8px; }
              .custom-table th { background-color: #f2f2f2; text-align: left; }
              .custom-table .green-header { background-color: #d4edda; }
              .margin-bottom { margin-bottom: 50px; }
              .farm-description { display: flex; align-items: center; margin-bottom: 15px; }
              .farm-description img { margin-right: 15px; width: 200px; height: 200px; object-fit: cover; }
              
              @media (max-width: 768px) {
                .fixed-size { width: 100%; height: auto; }
                .narrow-paragraph { padding: 0 15px; }
                .custom-table, .custom-table th, .custom-table td { font-size: 14px; }
                .farm-description img { width: 100%; height: auto; margin-right: 10px; }
                .margin-bottom { margin-bottom: 30px; }
              }
            `}
          </style>
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="row">
                {images.slice(0, 3).map((image, index) => (
                  <div className="col-4" key={index}>
                    <img
                      src={image}
                      alt={`Potato field ${index + 1}`}
                      className="img-thumbnail fixed-size"
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-2">
                {images.slice(3).map((image, index) => (
                  <div className="col-4" key={index + 3}>
                    <img
                      src={image}
                      alt={`Potato field ${index + 4}`}
                      className="img-thumbnail fixed-size"
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-3 narrow-paragraph">
                <div className="col-12">
                  <p>
                    Potatoes have been a dietary staple for centuries,
                    originally cultivated in the Andes mountains of South
                    America. As one of the most widely grown root vegetables in
                    the world, the potato plays a vital role in global food
                    security, providing essential nutrients and energy. But did
                    you know that organically grown potatoes offer even more
                    benefits?
                  </p>
                  <p>
                    Why Choose Organic Potatoes? Organic farming emphasizes
                    sustainability, reducing the use of synthetic pesticides and
                    fertilizers. This ensures that your potatoes are grown in
                    healthier soils, with a focus on environmental conservation.
                    By choosing organic, you’re not only getting a superior
                    taste but also supporting a more eco-friendly approach to
                    farming.
                  </p>
                </div>
              </div>
              <h3>
                <strong>Nutrition Table</strong>
              </h3>
              <table className="custom-table margin-bottom">
                <thead>
                  <tr>
                    <th className="green-header">Nutrient</th>
                    <th className="green-header">Amount (per 150g potato)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Calories</td>
                    <td>110 kcal</td>
                  </tr>
                  <tr>
                    <td>Carbohydrates</td>
                    <td>26g</td>
                  </tr>
                  <tr>
                    <td>Protein</td>
                    <td>3g</td>
                  </tr>
                  <tr>
                    <td>Fat</td>
                    <td>0g</td>
                  </tr>
                  <tr>
                    <td>Fiber</td>
                    <td>3g</td>
                  </tr>
                  <tr>
                    <td>Sugar</td>
                    <td>1g</td>
                  </tr>
                  <tr>
                    <td>Vitamin C</td>
                    <td>15mg (25% of DV)</td>
                  </tr>
                  <tr>
                    <td>Potassium</td>
                    <td>620mg (18% of DV)</td>
                  </tr>
                  <tr>
                    <td>Vitamin B6</td>
                    <td>0.3mg (20% of DV)</td>
                  </tr>
                  <tr>
                    <td>Iron</td>
                    <td>1mg (5% of DV)</td>
                  </tr>
                  <tr>
                    <td>Magnesium</td>
                    <td>30mg (8% of DV)</td>
                  </tr>
                  <tr>
                    <td>Folate</td>
                    <td>20mcg (5% of DV)</td>
                  </tr>
                </tbody>
              </table>
              <h3>
                <strong>Our Organic Farms</strong>
              </h3>
              <div className="farm-description">
                <img
                  src={FarmImage1}
                  alt="Organic Farm 1"
                  className="img-thumbnail"
                />
                <p>Farm at Nuwara Eliya Control Union Certified</p>
              </div>
              <div className="farm-description">
                <img
                  src={FarmImage2}
                  alt="Organic Farm 2"
                  className="img-thumbnail"
                />
                <p>Farm at Chilaw PGS Certified</p>
              </div>
            </div>
            <div className="col-12 col-md-4 ml-auto">
              <h2>Health Benefits of Organic Potatoes</h2>
              <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                {potatoBenefits.map((benefit, index) => (
                  <li key={index}>
                    <span style={{ color: "green" }}>✅</span>
                    <span style={{ color: "black" }}>
                      <strong>{benefit.text}:</strong> {benefit.details}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default PotatoPage;
